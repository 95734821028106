import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get } from 'lodash';

import { Container, withStyles } from '@material-ui/core';
import PreparationNumberOfPeople from '../../PreparationNumberOfPeople';
import PreparationTime from '../../PreparationTime';
import Alcoholicity from '../../Alcoholicity';
import FoodType from './FoodType';
import CategoryIcon from '../../CategoryIcon';
import ShareButtons from '../../ShareButtons';
import PrintButton from '../../../shared/PrintButton';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: { marginBottom: theme.spacing(6) },
    '@media print': {
      marginBottom: theme.spacing(2),
    },
  },
  time: {},
  foodType: {},
  numberOfPeople: {},
  categories: {},
  buttonGroup: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    '@media print': {
      display: 'none',
    },
  },
  printButton: {
    display: 'none',
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  shareButtons: {},
});

function PreparationInformation({ classes, className, pageData }) {
  const categories = get(pageData, 'categories');
  const alcoholicity = get(pageData, 'alcoholicity');
  const totalTime = get(pageData, 'totalTime');
  const preparationQuantity = get(pageData, 'preparationQuantity');

  return (
    <Container className={clsx(classes.root, className)}>
      <FoodType pageData={pageData} className={classes.foodType} />
      <PreparationNumberOfPeople data={preparationQuantity} className={classes.numberOfPeople} />
      <PreparationTime data={totalTime} className={classes.time} />
      <Alcoholicity data={alcoholicity} className={classes.alcoholicity} />
      <CategoryIcon data={categories} className={classes.categories} outlined showText />

      <div className={classes.buttonGroup}>
        <PrintButton className={classes.printButton} />
        <ShareButtons className={classes.shareButtons} pageData={pageData} />
      </div>
    </Container>
  );
}

PreparationInformation.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

PreparationInformation.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(PreparationInformation);
