import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { isEmpty, filter } from 'lodash';
import Button from '../../shared/Button';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  carouselWrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },

  carouselContentWrapper: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },

  carouselContent: {
    display: 'flex',
    transition: 'all 1.5s ease',

    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > *': {
      width: '100%',
      flexShrink: '0',
      flexGrow: '1',
    },
  },
  arrowIcon: {
    position: 'absolute',
    zIndex: '1',
    top: '60%',
    transform: 'translateY(-100%)',
    width: '36px',
    minWidth: '36px',
    height: '36px',
    borderRadius: '0px',
    '&:hover': {
      borderColor: theme.palette.common.gray,
      '&:disabled': {
        backgroundColor: theme.palette.common.gray,
      },
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.gray,
    },
  },

  leftArrow: {
    left: '0px',
    marginLeft: '-16px',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: { marginLeft: '-20px' },
  },

  rightArrow: {
    right: '0px',
    marginRight: '-16px',
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: { marginRight: '-20px' },
  },
  stepper: {
    width: '100%',
    flexGrow: 1,
    justifyContent: 'center',
  },
});

function Carousel({ classes, className, children }) {
  children = filter(children, child => {
    return !isEmpty(child);
  });

  const [show, setShow] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children.length);
  const [touchPosition, setTouchPosition] = useState(null);
  const [transformPercentage, setTransformPercentage] = useState(100);

  useEffect(() => {
    setLength(children.length);
    setShow(1);
    setTransformPercentage(100);
  }, [children, length]);

  if (isEmpty(children)) {
    return null;
  }
  const handleNext = () => {
    if (currentIndex < length - show) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };

  const handelPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };

  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleTouchMove = e => {
    const touchDown = touchPosition;
    if (touchDown === null) {
      return;
    }
    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleNext();
    }
    if (diff < -5) {
      handelPrev();
    }
    setTouchPosition(null);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.carouselWrapper}>
        <Button
          onClick={handelPrev}
          className={clsx(classes.arrowIcon, classes.leftArrow)}
          disabled={currentIndex === 0}>
          <KeyboardArrowLeft />
        </Button>

        <div className={classes.carouselContentWrapper} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
          <div
            className={classes.carouselContent}
            style={{ transform: `translateX(-${currentIndex * transformPercentage}%)` }}>
            {children}
          </div>
        </div>

        <Button
          onClick={handleNext}
          className={clsx(classes.arrowIcon, classes.rightArrow)}
          disabled={currentIndex === length - show}>
          <KeyboardArrowRight />
        </Button>
      </div>
    </div>
  );
}

Carousel.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

Carousel.defaultProps = {
  classes: {},
  className: null,
  children: null,
};

export default withStyles(styles)(Carousel);
