import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';

import PrintIcon from '@material-ui/icons/Print';
import { useTranslation } from 'react-i18next';
import { clickPrintButton } from '../../../utils/analyticUtils';

import Button from '../Button';

const styles = () => ({
  root: {
    '@media print': {
      display: 'none !important',
    },
  },
});

function PrintButton({ classes, className }) {
  const { t } = useTranslation();

  const handleClick = () => {
    clickPrintButton();
    window.print();
  };

  return (
    <Button
      className={clsx(classes.root, className)}
      variant="outlined"
      onClick={handleClick}
      startIcon={<PrintIcon />}>
      {t('PrintButton.label')}
    </Button>
  );
}

PrintButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  to: PropTypes.string,
};

PrintButton.defaultProps = {
  classes: {},
  className: null,
  children: null,
  to: null,
};

export default withStyles(styles)(PrintButton);
