import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';

import { withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { contentfulContentTypes, breakPoints } from '../../../../constants';

import CloudinaryImage from '../../../shared/CloudinaryImage';

const styles = () => ({
  root: {
    height: 'auto',
    '@media print': {
      maxHeight: '330px',
    },
  },
});

function HeroImage({ classes, className, pageData, mobileHeight, desktopHeight }) {
  const altText = get(pageData, 'heroImage.imageAltText');
  const titleText = get(pageData, 'heroImage.imageTitleText');
  const publicId = get(pageData, 'heroImage.cloudinaryImage.0.public_id');
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    if (!isUndefined(width)) {
      if (width > breakPoints.sm) {
        setIsMobile(false);
      }
      if (width < breakPoints.sm) {
        setIsMobile(true);
      }
    }
    return () => {
      return null;
    };
  }, [width]);

  const contentType = get(pageData, 'internal.type');

  const height = useMemo(() => {
    if (isEqual(contentType, contentfulContentTypes.AdvancedPage)) {
      return '800';
    }
    return desktopHeight;
  }, [desktopHeight, contentType]);

  const variants = useMemo(
    () => [
      {
        transformation: 'CardImage-Mobile',
        width: 600,
      },
      {
        transformation: 'HeroFullWidthImage-SM-SM',
        width: 768,
      },
      {
        transformation: isEqual(contentType, contentfulContentTypes.AdvancedPage)
          ? 'HeroFullWidthImage-MD-LG'
          : 'HeroFullWidthImage-MD-MD',
        width: 1024,
      },
      {
        transformation: isEqual(contentType, contentfulContentTypes.AdvancedPage)
          ? 'HeroFullWidthImage-LG-LG'
          : 'HeroFullWidthImage-LG-MD',
        width: 1440,
      },
      {
        transformation: isEqual(contentType, contentfulContentTypes.AdvancedPage)
          ? 'HeroFullWidthImage-XL-LG'
          : 'HeroFullWidthImage-LG-MD',
        width: 2048,
      },
      {
        transformation: isEqual(contentType, contentfulContentTypes.AdvancedPage) ? '' : 'HeroFullWidthImage-LG-MD',
        width: 3000,
        parameters: {
          c: 'fill',
          h: height,
        },
      },
    ],
    [contentType, height],
  );

  return (
    <CloudinaryImage
      className={clsx(classes.root, className)}
      element="picture"
      publicId={publicId}
      variants={variants}
      alt={altText}
      title={titleText}
      width="100%"
      height={isMobile ? mobileHeight : height}
    />
  );
}

HeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
  mobileHeight: PropTypes.string,
  desktopHeight: PropTypes.string,
};

HeroImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
  mobileHeight: '350',
  desktopHeight: '600',
};

export default withStyles(styles)(HeroImage);
