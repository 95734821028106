import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import { withStyles, Typography, Container } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    },
  },
});

function RecipeAuthor({ classes, className, pageData }) {
  const author = get(pageData, 'author');
  const { t } = useTranslation();
  if (!author) {
    return null;
  }

  return (
    <Container className={clsx(classes.root, className)}>
      <Typography variant="body1">
        <strong>{t('Recipe.author')}</strong>
        {`: ${author}`}
      </Typography>
    </Container>
  );
}

RecipeAuthor.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

RecipeAuthor.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RecipeAuthor);
