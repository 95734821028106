import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../../constants';
import { siteId } from '../../../../sites';

import ViinimaaProductMonopolyNumber from './viinimaa';

function ProductMonopolyNumber(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaProductMonopolyNumber {...props} />;
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaProductMonopolyNumber {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaProductMonopolyNumber {...props} />;
  }

  return null;
}

export default ProductMonopolyNumber;
