import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isNull, isUndefined } from 'lodash';
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'block',
    margin: theme.spacing(2, 4, 2, 0),
  },
  foodType: {},
});

function FoodType({ classes, className, pageData }) {
  const foodType = get(pageData, 'foodType.0.foodType');
  if (isNull(foodType) || isUndefined(foodType)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Typography>{`${foodType}`}</Typography>
    </div>
  );
}

FoodType.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FoodType.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(FoodType);
