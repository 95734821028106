import { each, concat, isEqual, get, isEmpty } from 'lodash';

/**
 *
 * @param {{ raw: string }} richText
 * @returns {[{ nodeType: string, content: [] }]}
 */
export const getRichTextNodes = richText => {
  return [JSON.parse(get(richText, 'raw', '{}'))];
};

export const getRichTextNodeValues = (nodes, nodeType) => {
  let values = [];
  each(nodes, node => {
    if (isEqual(get(node, 'nodeType'), nodeType)) {
      values.push(get(node, 'value'));
    }
    if (!isEmpty(get(node, 'content'))) {
      values = concat([], values, getRichTextNodeValues(get(node, 'content'), nodeType));
    }
  });
  return values;
};
