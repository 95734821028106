import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty } from 'lodash';
import { Container, withStyles } from '@material-ui/core';

import FeaturedProduct from './FeaturedProduct';
import Carousel from '../Carousel';

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0px',
    flexGrow: 1,
    justifyContent: 'space-between ',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-20),
      marginBottom: theme.spacing(9),
      minHeight: '80px',
    },
    '@media print': {
      display: 'none',
    },
  },
  carousel: { position: 'relative', width: '100%', marginBottom: theme.spacing(2) },
  contentCard: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  featuredProduct: {},
  divider: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.common.lightGray,
    marginBottom: '6px',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-5),
    },
  },
});

function FeaturedProductsCarousel({ classes, className, pageData, ...otherProps }) {
  const featuredProducts = get(pageData, 'featuredProducts');

  if (isEmpty(featuredProducts)) {
    return null;
  }

  return (
    <Container className={clsx(classes.root, className)}>
      <Carousel className={classes.carousel}>
        {map(featuredProducts, (featuredProduct, index) => {
          if (isEmpty(get(featuredProduct, 'relatedProduct'))) {
            return null;
          }
          return (
            <div className={classes.contentCard} key={index}>
              <FeaturedProduct data={featuredProduct} key={index} className={classes.featuredProduct} {...otherProps} />
            </div>
          );
        })}
      </Carousel>
    </Container>
  );
}

FeaturedProductsCarousel.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

FeaturedProductsCarousel.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(FeaturedProductsCarousel);
