import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, map, isEmpty, concat, compact } from 'lodash';

import { withStyles } from '@material-ui/core';
import Visible from '../../../shared/Visible';
import ThumbnailImage from '../../../shared/ThumbnailImage';
import Link from '../../../navigation/Link';

import Button from '../../../shared/Button';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  icon: { margin: theme.spacing(1, 4, 1, 0) },
  image: { borderRadius: '0' },
  category: {
    backgroundColor: ({ outlined }) => {
      if (outlined) {
        return theme.palette.category.outlined.background;
      }
      return 'transparent';
    },
    color: ({ outlined }) => {
      if (outlined) {
        return theme.palette.category.outlined.text;
      }
      return theme.palette.category.text;
    },

    fontSize: '12px',
    marginRight: theme.spacing(2),
    marginBottom: '4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    background: 'none',
    padding: ({ outlined }) => {
      if (outlined) {
        return theme.spacing(1);
      }
      return '0';
    },
    border: '0px',
    '&:hover': {
      color: theme.palette.category.hoverText,
      border: '0px',
      boxShadow: 'none',

      backgroundColor: 'transparent',
    },
  },
});

function CategoryIcon({ classes, className, data, showText }) {
  if (isEmpty(data)) {
    return null;
  }
  const iconCategories = [];
  const textCategories = [];

  map(data, category => {
    if (isEmpty(get(category, 'categoryIcon'))) {
      textCategories.push(category);
    } else {
      iconCategories.push(category);
    }
  });
  const categories = compact(concat(iconCategories, textCategories));
  return (
    <div className={clsx(classes.root, className)}>
      {map(categories, (category, index) => {
        const categoryId = get(category, 'contentful_id');
        const categoryKey = `${categoryId}-${index}`;
        const fullPath = get(category, 'fullPath');
        const title = get(category, 'title');
        const categoryIcon = get(category, 'categoryIcon.[0]');

        return (
          <div key={categoryKey} className={clsx(classes.root, className)}>
            <Visible visible={!isEmpty(categoryIcon)}>
              <Link to={fullPath} className={classes.icon}>
                <ThumbnailImage
                  className={classes.image}
                  transformation="FullWidthImage-XS-IN"
                  data={categoryIcon}
                  width="auto"
                  height="45"
                />
              </Link>
            </Visible>
            <Visible visible={isEmpty(categoryIcon) && !isEmpty(title) && !isEmpty(fullPath) && showText}>
              <div key={categoryKey}>
                <Button to={fullPath} size="small" className={classes.category}>
                  {title}
                </Button>
              </div>
            </Visible>
          </div>
        );
      })}
    </div>
  );
}

CategoryIcon.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  outlined: PropTypes.bool,
  showText: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

CategoryIcon.defaultProps = {
  classes: {},
  className: null,
  data: null,
  outlined: false,
  showText: false,
};

export default withStyles(styles)(CategoryIcon);
