import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Container, Typography, withStyles } from '@material-ui/core';
import { contentPageMaxWidth } from '../../../../constants';

const styles = theme => ({
  root: {
    display: 'block',
  },
  title: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3, 0),
    },
  },
});

function Title({ classes, className, maxWidth, pageData }) {
  let title = get(pageData, 'title');

  if (isEmpty(title)) {
    title = get(pageData, 'name');
  }

  if (isEmpty(title)) {
    return null;
  }

  return (
    <Container maxWidth={maxWidth !== '' ? maxWidth : contentPageMaxWidth} className={clsx(classes.root, className)}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
    </Container>
  );
}

Title.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  maxWidth: PropTypes.oneOf(['', false, 'xs', 'sm', 'md', 'lg', 'xl']),
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Title.defaultProps = {
  classes: {},
  className: null,
  maxWidth: '',
  pageData: null,
};

export default withStyles(styles)(Title);
