import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { get, isEmpty } from 'lodash';
import { Typography, withStyles } from '@material-ui/core';

const styles = () => ({
  root: { display: 'flex' },
  text: { fontFamily: '"Montserrat", serif', fontWeight: 'bold', fontSize: '12px' },
  label: { paddingRight: '16px' },
});

function ProductMonopolyNumber({ className, classes, productData, ...otherProps }) {
  const { t } = useTranslation();
  const monopolyNumber = get(productData, 'attributes.monopoly_number.value');

  if (isEmpty(monopolyNumber)) {
    return null;
  }
  let label = get(productData, 'attributes.monopoly_number.label');
  if (isEmpty(label)) {
    label = t('ProductPage.ProductInfo.MonopolyNumber');
  }
  return (
    <div className={clsx(classes.root, className)}>
      <Typography {...otherProps} className={clsx(classes.text, classes.label)}>{`${label}:`}</Typography>
      <Typography {...otherProps} className={classes.text}>
        {monopolyNumber}
      </Typography>
    </div>
  );
}

ProductMonopolyNumber.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  productData: PropTypes.object,
};

ProductMonopolyNumber.defaultProps = {
  classes: {},
  className: null,
  productData: null,
};

export default withStyles(styles)(ProductMonopolyNumber);
