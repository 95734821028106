import React from 'react';

import { graphql } from 'gatsby';

import CommonRecipePageTemplate from './RecipePageTemplate';
import AnoraProRecipePageTemplate from './anoraPro';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function RecipePageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProRecipePageTemplate {...props} />;
  }
  return <CommonRecipePageTemplate {...props} />;
}

export const pageQuery = graphql`
  query RecipePageTemplate($pageId: String!) {
    contentfulRecipePage(id: { eq: $pageId }) {
      id
      contentful_id
      createdAt
      title
      menuTitle
      author
      authorType
      shortDescription
      fullPath
      languageCode
      alcoholicity
      preparationQuantity
      preparationTime
      cookTime
      totalTime
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      categories {
        ...CategoryFragment
      }
      recipeCategory {
        ...CategoryFragment
      }
      cuisine {
        ...CategoryFragment
      }
      preparationSections {
        ...PreparationSectionsFragment
      }
      relatedPages {
        ...RelatedPageFragment
      }
      brands {
        ...BrandPageFragment
      }
      featuredProducts {
        ...FeaturedProductFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default RecipePageTemplate;
