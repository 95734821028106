import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';

import { withStyles, Typography, Divider } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakPoints } from '../../../../constants';
import ThumbnailImage from '../../../shared/ThumbnailImage';
import ProductMonopolyPrice from '../../../shared/Products/ProductMonopolyPrice';
import ProductMonopolyNumber from '../../../shared/Products/ProductMonopolyNumber';
import ProductMonopolyLink from '../../../shared/Products/ProductMonopolyLink';

import Categories from '../../Categories';
import Link from '../../../navigation/Link';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 3, 3, 3),
    },
  },
  productImage: {
    borderRadius: '0',
    paddingRight: theme.spacing(3),
    width: 'auto',
    maxHeight: '285px',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      maxHeight: 'none',
      paddingRight: theme.spacing(6),
    },
  },
  image: { minWidth: '90px' },

  category: {
    marginBottom: '8px',
  },
  link: { color: 'inherit' },
  name: {
    marginBottom: '12px',
    color: 'inherit',
    fontSize: '1.1rem',
    [theme.breakpoints.up('md')]: { fontSize: '1.375rem' },
  },
  country: {
    color: theme.palette.common.mediumGray2,
    marginBottom: theme.spacing(2),
  },
  productInfo: {
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word',
    hyphens: 'auto',
    justifyContent: 'center',
    flexBasis: '60%',
    maxWidth: '60%',
    [theme.breakpoints.up('md')]: {
      flexBasis: 'auto',
      maxWidth: 'none',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(18),
    },
  },
  price: { marginBottom: '12px', fontWeight: 'bold' },

  imageWrapper: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexBasis: '40%',
    maxWidth: '40%',
    [theme.breakpoints.up('md')]: {
      display: 'inline',
      flexBasis: 'auto',
      maxWidth: 'none',
      alignContent: 'stretch',
      alignItems: 'stretch',
    },
  },

  divider: {
    zIndex: '-1',
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    backgroundColor: theme.palette.common.lightGray,
  },
  monopolyNr: {
    color: theme.palette.common.mediumGray,
    padding: theme.spacing(0, 0, 2, 0),
  },
});

function FeaturedProduct({
  classes,
  className,
  data,
  defaultImage,
  showProductMonopolyNumber,
  showProductMonopolyLink,
}) {
  let featuredProduct = get(data, 'featuredProduct');

  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    // if (!isUndefined(width)) {
    if (width > breakPoints.md) {
      setIsMobile(false);
    }
    if (width < breakPoints.md) {
      setIsMobile(true);
    }
    // }
  }, [width]);

  if (isEmpty(featuredProduct)) {
    featuredProduct = data;
  }
  let description = get(featuredProduct, 'description');
  if (isEmpty(description)) {
    description = get(featuredProduct, 'featuredProduct.description');
  }

  const fullPath = get(featuredProduct, 'relatedProduct.fullPath');
  const name = get(featuredProduct, 'relatedProduct.name');

  let image = get(featuredProduct, 'relatedProduct.heroImage');
  if (isEmpty(get(image, 'cloudinaryImage.0.public_id'))) {
    image = {
      cloudinaryImage: [{ public_id: defaultImage }],
      imageAltText: name,
      imageTitleText: name,
    };
  }
  const categories = get(featuredProduct, 'relatedProduct.categories');
  const attributes = get(featuredProduct, 'relatedProduct.attributes');

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <div className={classes.imageWrapper}>
          <Link to={fullPath} className={classes.link}>
            <ThumbnailImage
              data={image}
              classes={{ root: classes.productImage }}
              transformation={isMobile ? 'ProductThumbnailImage-IN-SM' : 'ProductThumbnailImage-IN-MD'}
              height={isMobile ? 'auto' : '400'}
              width={isMobile ? '100' : 'auto'}
            />
          </Link>
        </div>

        <div className={classes.productInfo}>
          <Categories categoriesData={categories} outlined classes={{ category: classes.category }} />
          <Link to={fullPath} className={classes.link}>
            <Typography variant="h2" align="left" className={classes.name}>
              {name}
            </Typography>
          </Link>
          <Typography variant="body1" align="left" className={classes.country}>
            {get(attributes, 'country_of_origin.value')}
          </Typography>
          {showProductMonopolyNumber && (
            <ProductMonopolyNumber
              productData={get(featuredProduct, 'relatedProduct')}
              className={classes.monopolyNr}
            />
          )}
          <ProductMonopolyPrice productData={get(featuredProduct, 'relatedProduct')} className={classes.price} />

          {showProductMonopolyLink && <ProductMonopolyLink productData={get(featuredProduct, 'relatedProduct')} />}
        </div>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}

FeaturedProduct.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  defaultImage: PropTypes.string,
  showProductMonopolyNumber: PropTypes.bool,
  showProductMonopolyLink: PropTypes.bool,
};

FeaturedProduct.defaultProps = {
  classes: {},
  className: null,
  data: null,
  defaultImage: false,
  showProductMonopolyNumber: false,
  showProductMonopolyLink: false,
};

export default withStyles(styles)(FeaturedProduct);
