import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map, isEmpty, filter, includes } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';
import Visible from '../../../shared/Visible';
import RelatedPage from './RelatedPage';
import { contentfulContentTypes } from '../../../../constants';

import '../../Content';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.lighterGray,
    padding: theme.spacing(4, 0),
    '@media print': {
      display: 'none',
    },
  },
  relatedpages: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: ({ gap }) => {
      if (gap) {
        return `${gap}px`;
      }
      return '0';
    },
  },
});

function RelatedPages({ classes, className, pageData }) {
  const relatedPages = get(pageData, 'relatedPages');

  const hasSearchModule = !isEmpty(
    filter(get(pageData, 'content.references'), reference => {
      return includes(reference, contentfulContentTypes.SearchModule);
    }),
  );
  if (hasSearchModule) {
    return null;
  }

  return (
    <Visible hidden={isEmpty(relatedPages)}>
      <div className={clsx(classes.root, className)}>
        <Container disableGutters className={classes.relatedpages}>
          {map(relatedPages, (relatedpage, index) => {
            return <RelatedPage key={index} data={relatedpage} />;
          })}
        </Container>
      </div>
    </Visible>
  );
}

RelatedPages.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedPages.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(RelatedPages);

export const query = graphql`
  fragment RelatedPageFragment on PageUnion {
    ...ContentLandingPageFragment
    ...ContentAdvancedPageFragment
    ...ContentArticlePageFragment
    ...ContentRecipePageFragment
    ...ContentBrandFragment
    ...ContentCategoryFragment
    ...ContentProductReferenceFragment
  }
`;
