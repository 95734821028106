import React from 'react';
import PropTypes from 'prop-types';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import RecipePageTemplate from '../RecipePageTemplate';

function AnoraProRecipePageTemplate({ ...props }) {
  return <RecipePageTemplate {...props} />;
}

AnoraProRecipePageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProRecipePageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withAuthenticationRequired(AnoraProRecipePageTemplate);
