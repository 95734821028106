import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, map } from 'lodash';
import { graphql } from 'gatsby';
import { Container, withStyles } from '@material-ui/core';
import BrandPage from './BrandPage';

const styles = theme => ({
  root: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    flexWrap: 'wrap',
    '@media print': {
      display: 'none',
    },
  },
});

function BrandPages({ classes, className, pageData }) {
  const brands = get(pageData, 'brands');
  if (brands) {
    return (
      <Container disableGutters className={clsx(classes.root, className)}>
        {map(brands, (brand, index) => {
          return <BrandPage key={index} data={brand} />;
        })}
      </Container>
    );
  }

  return null;
}

BrandPages.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BrandPages.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(BrandPages);

export const query = graphql`
  fragment BrandPageFragment on ContentfulBrand {
    id
    title
    menuTitle
    shortDescription
    fullPath
    languageCode
    internal {
      type
    }
    heroImage {
      ...HeroImageFragment
    }
    categories {
      ...CategoryFragment
    }
  }
`;
