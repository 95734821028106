/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import isEqual from 'lodash/isEqual';
import { withStyles } from '@material-ui/core';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import SeoFields from '../../components/page/SeoFields';
import HeroImage from '../../components/page/HeroImage';
import Title from '../../components/page/Title';
import RelatedPages from '../../components/page/RelatedPages';
import BrandPages from '../../components/page/BrandPages';
import FeaturedProductsCarousel from '../../components/page/FeaturedProductsCarousel';
import ShortDescription from '../../components/page/ShortDescription';
import PreparationInformation from '../../components/page/PreparationInformation';
import PreparationSections from '../../components/page/PreparationSections';
import RecipeStructuredData from '../../components/page/RecipeStructuredData';
import SiteNotification from '../../components/page/SiteNotifications';
import AnalyticsModule from '../../components/module/AnalyticsModule';
import withSecondaryTheme from '../../components/shared/ThemeStyle/withSecondaryTheme';
import RecipeAuthor from '../../components/page/RecipeAuthor';
import PageBrowseTracker from '../../components/page/PageBrowseTracker';

import useInitI18next from '../../components/hooks/useInitI18next';
import useInitLuxon from '../../components/hooks/useInitLuxon';

import { siteIds, contentfulContentTypes } from '../../constants';
import { siteId } from '../../sites';

const styles = theme => ({
  root: {
    display: 'block',
    ...theme.palette.pageTemplates.otherPageTemplates,
    ...theme.palette.pageTemplates.recipePage,
    '@media print': {
      paddingTop: '60px',
    },
  },
  wrapper: {
    '@media print': {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'flex-start',
    },
  },
  titleContainer: {
    '@media print': {
      flexBasis: '50%',
    },
  },
  title: {
    '@media print': {
      marginTop: '-10px',
    },
  },
  heroImage: {
    '@media print': {
      flexBasis: '50%',
    },
  },
});

function RecipePageTemplate({ classes, data }) {
  const pageData = get(data, 'contentfulRecipePage');
  const analytics = get(pageData, 'mainTheme.analytics');

  useInitI18next();
  useInitLuxon();

  let showTitle = true;

  // Don't show title if this is Skagerrak's recipe page
  if (isEqual(siteId, siteIds.Skagerrak)) {
    const pageType = get(pageData, 'internal.type');
    showTitle = pageType ? !isEqual(pageType, contentfulContentTypes.RecipePage) : true;
  }

  return (
    <div className={classes.root}>
      <PageBrowseTracker pageData={pageData} />
      <SeoFields pageData={pageData} />
      <RecipeStructuredData pageData={pageData} />
      <Header pageData={pageData} />
      <AnalyticsModule data={analytics} />
      <main className={classes.main}>
        <SiteNotification pageData={pageData} />
        <div className={classes.wrapper}>
          {showTitle && (
            <div className={classes.titleContainer}>
              <Title pageData={pageData} className={classes.title} />
              <ShortDescription pageData={pageData} />
            </div>
          )}
          <HeroImage pageData={pageData} className={classes.heroImage} />
        </div>
        <FeaturedProductsCarousel pageData={pageData} />
        <PreparationInformation pageData={pageData} />
        <PreparationSections pageData={pageData} />
        <RecipeAuthor pageData={pageData} />
        <RelatedPages pageData={pageData} />
        <BrandPages pageData={pageData} />
      </main>
      <Footer pageData={pageData} />
    </div>
  );
}

RecipePageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

RecipePageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default withSecondaryTheme(withStyles(styles)(RecipePageTemplate));
