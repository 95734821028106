import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import FolkofolkRecipeAuthor from './folkofolk';

function RecipeAuthor(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkRecipeAuthor {...props} />;
  }

  return null;
}

export default RecipeAuthor;
