import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { withStyles, Typography, Container } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'block',
    backgroundColor: '#fffdee',
    borderBottom: 'solid 1px #dcca9b',
  },

  shortDescription: {
    padding: theme.spacing(1, 0, 3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 0, 3, 0),
    },
  },
});

function ShortDescription({ classes, className, pageData }) {
  const shortDescription = get(pageData, 'shortDescription');

  if (isEmpty(shortDescription)) {
    return null;
  }
  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="md">
        <Typography className={classes.shortDescription}>{shortDescription}</Typography>
      </Container>
    </div>
  );
}

ShortDescription.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

ShortDescription.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ShortDescription);
