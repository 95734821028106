/* eslint-disable react/no-danger */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get, isEmpty, isNil, filter, map, concat, compact, flatten } from 'lodash';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { contentfulContentTypes } from '../../../../constants';

import { createCloudinaryImageUrl } from '../../../../utils/cloudinaryUtils';
import { getRichTextNodeValues, getRichTextNodes } from '../../../../utils/richTextUtils';

const getTitle = pageData => {
  let title = get(pageData, 'title');
  if (isEmpty(title)) {
    title = get(pageData, 'menuTitle');
  }
  return title;
};
const getPreparationSectionTitle = (preparationSection, pageData) => {
  let preparationSectionTitle = get(preparationSection, 'title');
  if (isEmpty(preparationSectionTitle)) {
    preparationSectionTitle = getTitle(pageData);
  }
  return preparationSectionTitle;
};

const getRecipeCategory = pageData => {
  let category = get(pageData, 'recipeCategory.0.title');
  if (isEmpty(category)) {
    category = get(pageData, 'recipeCategory.0.menuTitle');
  }
  return category;
};

const getCuisine = pageData => {
  let category = get(pageData, 'cuisine.title');
  if (isEmpty(category)) {
    category = get(pageData, 'cuisine.menuTitle');
  }
  return category;
};
const getAuthor = pageData => {
  const author = get(pageData, 'author', null);
  const type = get(pageData, 'authorType.0', 'Person');
  if (author) {
    return {
      '@type': type,
      name: author,
    };
  }
  return '';
};

const getHeroImageUrl = pageData => {
  const publicId = get(pageData, 'heroImage.cloudinaryImage.0.public_id');
  if (isEmpty(publicId)) {
    return undefined;
  }
  return createCloudinaryImageUrl('OgImage-MD-MD', publicId);
};

const getPreparationTime = pageData => {
  const preparationTime = get(pageData, 'preparationTime');
  if (isNil(preparationTime)) {
    return undefined;
  }
  const hours = preparationTime >= 60 ? Math.round(preparationTime / 60) : 0;
  const minutes = preparationTime % 60 || 0;
  return `PT${hours}H${minutes}M`;
};

const getCookTime = pageData => {
  const cookTime = get(pageData, 'cookTime');
  if (isNil(cookTime)) {
    return undefined;
  }
  const hours = cookTime >= 60 ? Math.round(cookTime / 60) : 0;
  const minutes = cookTime % 60 || 0;
  return `PT${hours}H${minutes}M`;
};

const getTotalTime = pageData => {
  const totalTime = get(pageData, 'totalTime');
  if (isNil(totalTime)) {
    return undefined;
  }
  const hours = totalTime >= 60 ? Math.round(totalTime / 60) : 0;
  const minutes = totalTime % 60 || 0;
  return `PT${hours}H${minutes}M`;
};

const getPreparationSteps = preparationSection => {
  const preparationSteps = filter(get(preparationSection, 'preparationSteps.references'), {
    internal: { type: contentfulContentTypes.PreparationStep },
  });
  const preparationStepNodes = getRichTextNodes(get(preparationSection, 'preparationSteps'));
  const preparationStepNodeTexts = compact(getRichTextNodeValues(preparationStepNodes, 'text'));
  const preparationStepsFromTexts = map(preparationStepNodeTexts, preparationStepNodeText => ({
    preparationStep: preparationStepNodeText,
  }));
  return concat([], preparationSteps, preparationStepsFromTexts);
};

const getPreparationSections = pageData => {
  const preparationSections = filter(get(pageData, 'preparationSections.references'), {
    internal: { type: contentfulContentTypes.PreparationSection },
  });
  return preparationSections;
};

const getPreparationIngredients = pageData => {
  const preparationSections = getPreparationSections(pageData);

  let preparationIngredients = [];

  map(preparationSections, preparationSection => {
    const ingredients = get(preparationSection, 'ingredients');

    if (!isNil(ingredients)) {
      const ingredientNodes = getRichTextNodes(ingredients);

      const ingredientNodeTexts = compact(getRichTextNodeValues(ingredientNodes, 'text'));
      preparationIngredients = concat(preparationIngredients, ingredientNodeTexts);
    } else {
      preparationIngredients = concat(
        preparationIngredients,
        map(flatten(get(preparationSection, 'preparationIngredients')), 'ingredient'),
      );
    }
  });
  return preparationIngredients;
};

function RecipeStructuredData({ pageData }) {
  const structuredData = useMemo(() => {
    return JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Recipe',
      description: get(pageData, 'shortDescription'),
      datePublished: get(pageData, 'createdAt'),
      recipeYield: get(pageData, 'preparationQuantity'),
      name: getTitle(pageData),
      author: getAuthor(pageData),
      recipeCategory: getRecipeCategory(pageData),
      recipeCuisine: getCuisine(pageData),
      image: getHeroImageUrl(pageData),
      prepTime: getPreparationTime(pageData),
      cookTime: getCookTime(pageData),
      totalTime: getTotalTime(pageData),
      recipeIngredient: getPreparationIngredients(pageData),
      recipeInstructions: map(getPreparationSections(pageData), preparationSection => ({
        '@type': 'HowToSection',
        name: getPreparationSectionTitle(preparationSection, pageData),
        itemListElement: map(getPreparationSteps(preparationSection), preparationStep => ({
          '@type': 'HowToStep',
          text: get(preparationStep, 'preparationStep'),
        })),
      })),
    });
  }, [pageData]);

  return (
    <Helmet>
      <script type="application/ld+json">{structuredData}</script>
    </Helmet>
  );
}

RecipeStructuredData.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

RecipeStructuredData.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default RecipeStructuredData;

export const query = graphql`
  fragment RichDataFragment on ContentfulRichData {
    pageTitle
    preparationTimeHours
    preparationTimeMinutes
  }
`;
