import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { floor, isNull, isUndefined } from 'lodash';

import { Typography, withStyles } from '@material-ui/core';
import personLogo from '../../../../images/viinimaa/icon-preparation-time.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 4, 1, 0),
  },
  svg: {},
  time: { paddingLeft: theme.spacing(1.5) },
});

function PreparationTime({ classes, className, data }) {
  if (isNull(data) || isUndefined(data)) {
    return null;
  }
  const hours = floor(data / 60);
  const min = data - hours * 60;

  return (
    <div className={clsx(classes.root, className)}>
      <img src={personLogo} alt="logo" width="auto" height="35" className={classes.svg} loading="lazy" />
      <Typography variant="body2" className={classes.time}>
        {`${hours} h ${min} min`}
      </Typography>
    </div>
  );
}

PreparationTime.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PreparationTime.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(PreparationTime);
