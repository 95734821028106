import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { brandSite, siteId } from '../../../sites';

import ViinimaaRelatedPages from './viinimaa';

function RelatedPages(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaRelatedPages {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaRelatedPages {...props} />;
  }
  if (isEqual(siteId, siteIds.Wennerco)) {
    return <ViinimaaRelatedPages gap="40" {...props} />;
  }
  if (brandSite) {
    return <ViinimaaRelatedPages {...props} />;
  }

  return null;
}

export default RelatedPages;
