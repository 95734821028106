import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { Container, Grid, Typography, withStyles } from '@material-ui/core';

import HeroImage from '../HeroImage';
import { contentfulContentTypes } from '../../../../constants';

import CloudinaryImage from '../../../shared/CloudinaryImage';

import '../../Content';

const styles = theme => ({
  container: {
    margin: '0 auto 20px',
    '@media print': {
      display: 'block',
      margin: '0 0 50px',
    },
  },
  gridContainer: {
    '@media print': {
      flexBasis: '100%',
    },
  },
  textOverlay: {
    position: 'absolute',
    top: '50%',
    left: '0',
    right: '0',
    transform: 'translate(-0%, -50%)',
    color: 'white',
    '@media print': {
      display: 'none',
    },
  },
  recipeDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.header.background,
    padding: '64px',
    color: theme.palette.common.white,
    '@media print': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      paddingLeft: 0,
    },
  },
  heroImage: {
    marginBottom: 0,
  },
});

function SkagerrakHeroImage({ classes, pageData }) {
  const title = get(pageData, 'title');
  const shortDescription = get(pageData, 'shortDescription');
  const pageType = get(pageData, 'internal.type');
  const publicId = get(pageData, 'heroImage.cloudinaryImage.0.public_id');
  const altText = get(pageData, 'heroImage.imageAltText');

  const variants = useMemo(
    () => [
      {
        parameters: {
          c: 'fill',
          w: '776',
          h: '750',
        },
        width: 776,
      },
      {
        parameters: {
          c: 'fill',
          w: '616',
          h: '650',
        },
        width: 650,
      },
    ],
    [],
  );

  if (isEqual(pageType, contentfulContentTypes.RecipePage)) {
    return (
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={0} alignItems="stretch" className={classes.gridContainer}>
          <Grid item xs={12} md={6} className={classes.recipeDescription}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            <Typography className={classes.shortDescription}>{shortDescription}</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CloudinaryImage
              classes={{ root: classes.heroImage, textOverlay: classes.textOverlay }}
              publicId={publicId}
              variants={variants}
              alt={altText}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  if (get(pageData, 'heroImage')) {
    return (
      <HeroImage
        pageData={pageData}
        disableMaxWidth={isEqual(pageType, contentfulContentTypes.RecipePage)}
        disableGutters
        mobileHeight="450"
        classes={{ textOverlay: classes.textOverlay }}
      />
    );
  }

  return null;
}

SkagerrakHeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

SkagerrakHeroImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(SkagerrakHeroImage);
