import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core';
import { get, isEmpty } from 'lodash';

import ProductCard from '../../../shared/Products/ProductCard';
import ListElementCard from '../../../shared/ListElementCard';
import RichText from '../../../shared/RichText';

const styles = () => ({
  root: {},
});

function RelatedPage({ classes, className, data, ...otherProps }) {
  const image = get(data, 'heroImage');
  const fullPath = get(data, 'fullPath');
  const shortDescription = get(data, 'shortDescription');
  const categories = get(data, 'categories');
  const relatedProduct = get(data, 'relatedProduct');
  const pageType = get(data, '__typename');

  let title = get(data, 'title');
  if (isEmpty(title)) {
    title = get(data, 'menuTitle');
  }
  if (!isEmpty(relatedProduct)) {
    return <ProductCard productData={relatedProduct} />;
  }
  if (isEmpty(fullPath) && isEmpty(title)) {
    return null;
  }

  return (
    <ListElementCard
      {...otherProps}
      className={clsx(classes.root, className)}
      richText={RichText}
      image={image}
      title={title}
      categories={categories}
      description={shortDescription}
      fullPath={fullPath}
      pageType={pageType}
    />
  );
}

RelatedPage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

RelatedPage.defaultProps = {
  classes: {},
  className: null,
  data: null,
};
export default withStyles(styles)(RelatedPage);
