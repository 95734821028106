import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';
import { withStyles } from '@material-ui/core';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakPoints } from '../../../../constants';

import CloudinaryImage from '../../../shared/CloudinaryImage';

const styles = () => ({
  root: {
    '@media print': {
      maxHeight: '330px',
    },
  },
});

function HeroImage({ classes, className, pageData }) {
  const altText = get(pageData, 'heroImage.imageAltText');
  const titleText = get(pageData, 'heroImage.imageTitleText');
  const publicId = get(pageData, 'heroImage.cloudinaryImage.0.public_id');
  const [isMobile, setIsMobile] = useState(true);
  const { width } = useWindowSize();

  useEffect(() => {
    // if (!isUndefined(width)) {
    if (width > breakPoints.md) {
      setIsMobile(false);
    }
    if (width < breakPoints.md) {
      setIsMobile(true);
    }
    // }
  }, [width]);

  const variants = useMemo(
    () => [
      {
        transformation: 'HeroFullWidthImage-SM-SM',
        width: 768,
      },
      {
        transformation: 'HeroFullWidthImage-MD-MD',
        width: 1024,
      },
      {
        transformation: 'FoF-HeroFullWidthImage-LG-LG',
        width: 1200,
      },
    ],
    [],
  );

  return (
    <CloudinaryImage
      className={clsx(classes.root, className)}
      publicId={publicId}
      variants={variants}
      alt={altText}
      title={titleText}
      width="100%"
      height={isMobile ? '400' : '800'}
    />
  );
}

HeroImage.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.object,
};

HeroImage.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(HeroImage);
