import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId, brandSite } from '../../../sites';

import ViinimaaHeroImage from './HeroImage';
import PhilipsonSoderbergHeroImage from './philipson-soderberg/HeroImage';
import SkagerrakHeroImage from './skagerrak/HeroImage';

function HeroImage(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaHeroImage {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <ViinimaaHeroImage {...props} />;
  }
  if (isEqual(siteId, siteIds.PhilipsonSoderberg)) {
    return <PhilipsonSoderbergHeroImage {...props} />;
  }
  if (isEqual(siteId, siteIds.Skagerrak)) {
    return <SkagerrakHeroImage {...props} />;
  }
  if (brandSite) {
    return <ViinimaaHeroImage {...props} />;
  }
}

export default HeroImage;
