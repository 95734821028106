import React from 'react';

import { isEqual } from 'lodash';

import { siteIds, productDefaultImage } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaFeaturedProductsCarousel from './viinimaa';

function FeaturedProductsCarousel(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return (
      <ViinimaaFeaturedProductsCarousel
        {...props}
        defaultImage={productDefaultImage.Viinimaa}
        showProductMonopolyLink
      />
    );
  }
  if (isEqual(siteId, siteIds.AnoraPro)) {
    return <ViinimaaFeaturedProductsCarousel {...props} defaultImage={productDefaultImage.Viinimaa} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return (
      <ViinimaaFeaturedProductsCarousel
        {...props}
        defaultImage={productDefaultImage.Folkofolk}
        showProductMonopolyNumber
      />
    );
  }

  return null;
}

export default FeaturedProductsCarousel;
